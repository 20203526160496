@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

html {

  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Nunito';
  font-size: 22px;
  color: rgb(0, 0, 0);
  background: linear-gradient(225deg, #ffffff, #5f2c82);
  overflow-x: hidden !important;
  /* #efcb90, #61605e */

}

#portrait {
  width: 600px;
  object-fit: cover;
  height: 600px;
  border-radius: 50%;

}


.home-div {
  display: flex;
  justify-content: space-around;
  height: 60vh;
}

.home-page {
  height: 100vh;
  width: 100vw;
  align-items: center;
}


.subject {
  margin: 100px;
}

.list-1 {
  width: 300px;
}

.tiny {
  font-size: 16px;
  font-style: italic;
}

/* Smooth Scroll */
[data-scrollbar] {
  height: 100vh;
  overflow: hidden;

  .home-page {}

  .scrollbar-track .scrollbar-track-y {
    background: rgb(122, 122, 122);

    .scrollbar-thumb-y {
      background: rgb(52, 52, 52);
    }
  }
}

