#lin-image {
    width: 300px;
    height: 600px;
    object-fit: contain;
}

#nunu-logo {
    width: 800px;
    height: 100px;
    object-fit: cover;
    margin-top: 100px;
}

#made-rich {
    width: 45vw;
    object-fit: contain;
    padding: 3em;
}

@media only screen and (max-width:1000px) {
    #made-rich {
        width: 90vw;
        object-fit: contain;
        padding: 3em;
    }

}