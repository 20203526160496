.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100vw;
    margin-top: 20vh;
    margin-bottom: 10vh;
}

.descr {
    width: 800px;
}

.frameworks {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.framework {
    height: 5vw;
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
}

#store {
    width: 500px;
    object-fit: cover;

}


#music {
    width: 800px;
    object-fit: scale-down;
}

#java {
    height: 120px;
    object-fit: contain;
}

#covid {
    width: 500px;
}

#lift {
    object-fit: contain;
    width: 500px;
}

#googleLogo {
    object-fit: contain;
    height: 3em;
    margin-top: 1em
}

.lift-img {
    object-fit: contain;
    width: 400px;
    height: 700px;
}

.main-button {
    width: 400px;
    height: 400px;
    margin: 100px;
    background: none;
    border-radius: 40px;
}

#web-button:hover {
    background: rgba(1, 17, 194, 0.233)
}

#proj-button:hover {
    background: rgba(1, 17, 194, 0.233)
}

#button-container {
    background: none;
}

@media only screen and (max-width:1000px) {
    .main-button {
        width: 15em;
        height: 15em;
        margin: 0px;
        margin-bottom: 50px;
    }

    #button-container {}

    .main-button img {
        height: 9em;

    }

    #ui-img {
        height: 9em !important;
    }

    .main-button h1 {
        font-size: 18px;
    }

    .project {
        flex-direction: column;
        align-items: center;
    }

    .descr {
        width: 350px;
    }

    .frameworks {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        margin-bottom: 30px;
        flex-wrap: wrap;

    }

    .lift-img {
        object-fit: contain;
        width: 15em;
        height: 25em;
    }

    .framework {
        height: 3em;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
    }

    #googleLogo {
        height: 1.5em;
    }

    #nunu-logo {
        margin-top: 0px !important;
        padding-top: 0px;
        width: 300px !important;
        object-fit: scale-down !important;
    }

    #smart-check-frame {
        height: 15em;
        width: 15em;
    }

    #lift {
        width: 15em;
        height: 15em;
    }

    #music {
        width: 17em;
    }

    #covid {
        width: 15em;
    }

    #leaflet {
        width: 6.8em;
        object-fit: scale-down;
    }

    #well-done {
        width: 15em;
    }
}