#lassonde {
    width: 500px;
}

#intro {
    width: 500px;
}

.list-1 {
    width: 500px;
    list-style-type: none;
}

#title {
    font-size: 60px;
    font-family: "NeutralFaceBold";
}


.header2 {
    text-decoration: underline;
}

.header3 {
    padding-top: 10px;
    font-size: 18px;
}

.list-2 {
    list-style-type: none;

}

.list-2 li {
    display: inline-block;
    margin-left: 1vw;
    margin-right: 1vw;
}


a {
    text-decoration: none;
    font-size: 20px;
    color: #130d17;
}

a:hover {
    color: #794f94;
}

.skill-box {
    width: 300px;
    height: 250px;
    border: 1px solid black;
    margin: 20px;
    padding: 5px;
}

.skill-header {
    font-size: 30px;
}

.skill-paragraph {
    font-size: 20px;
}

#about-exp {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

#box-container {
    display: flex;
    flex-direction: row;
}

.horizontal-list li {
    float: left;
}

.horizontal-list li {
    display: block;
    padding: 16px;
    text-align: center;
    text-decoration: none;
}

.med-header {
    font-size: 26px;
    margin-bottom: 0;
    padding-bottom: 0;
}

#language-list {
    margin-top: 0px;
    padding-left: 0px;
    margin-left: none !important;
}

#framework-list {
    margin-top: 0px;
    padding-left: 0px;
}

#down-button {
    -webkit-animation: breathing 1s ease-out infinite normal;
    animation: breathing 1s ease-out infinite normal;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -10em;
}

#down-button img {
    height: 5em;
}


#down-button-normal img {
    height: 5em;
}

#down-button-dark-normal img {
    height: 5em;
    filter: invert(99%) sepia(4%) saturate(87%) hue-rotate(178deg) brightness(116%) contrast(100%);

}

#down-button-dark {
    -webkit-animation: breathing 1s ease-out infinite normal;
    animation: breathing 1s ease-out infinite normal;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -10em;
}

#down-button-normal {
    background: none;
    border: none;
    cursor: pointer;
}

#down-button-dark-normal {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: -10em;
}


#down-button-dark img {
    height: 5em;
    filter: invert(99%) sepia(4%) saturate(87%) hue-rotate(178deg) brightness(116%) contrast(100%);

}

.lightHome {
    background: linear-gradient(225deg, #f8ffff, #b9f0f9);

}

.darkHome {
    background: linear-gradient(225deg, #082449, #1a0e22);

}

.darkHome * {
    color: white;
}


.tech-button {
    height: 8rem;
    width: 8rem;
}

.tech-button:hover {
    height: 8rem;
    width: 8rem;
    color: '#4f46e5'
}


#rotator-text {
    animation: inherit;
    animation-direction: reverse;

}



@keyframes rotationsOposite {
    to {
        transform: rotate(-360deg);
    }
}


@-webkit-keyframes breathing {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    25% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }
}


#button-container {
    -webkit-animation: breathing 3s ease-out infinite normal;
    animation: breathing 3s ease-out infinite normal;
}

#dl-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    -webkit-animation: breathing 1.5s ease-out infinite normal;
    animation: breathing 1.5s ease-out infinite normal;
}

#darkLightLogo {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 200px;
}

#resume-button {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    background: none;
    border: none;
    -webkit-animation: breathing 1.5s ease-out infinite normal;
    animation: breathing 1.5s ease-out infinite normal;
}

#resume-img {
    width: 80px;
    height: 80px;
}

.dl-img {
    background: none;
    height: 2em;
    width: 2em;
}

.dl-img-dark {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(158deg) brightness(104%) contrast(102%);
    background: none;
    height: 2em;
    width: 2em;
}

@media only screen and (max-width:1000px) {
    #home-root {
        display: flex;
        flex-direction: column;
    }

    #portrait {
        width: 9em;
        height: 9em;
        display: flex;
        padding: 5px;
    }

    #intro {
        width: 100vw;
        padding: 5px;
        flex-direction: column;
        display: flex;
        order: 2;
    }

    #title {
        font-size: 50px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .about {
        font-size: 16px;
        margin-top: 0px;
        padding-top: 0px;
    }

    #home-page {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100vw;
        height: 70vh;
    }

    #portrait-box {
        order: 1;
    }

    .App {
        width: 100vw;
    }

    #box-container {
        flex-direction: column;
    }

    .home-div {
        display: flex;
        justify-content: space-around;
        height: 60vh;
    }

    .home-page {
        height: initial;
        height: 50vh;
        width: 100vw;
        align-items: center;
    }

    #about-div {
        flex-direction: column;
    }

    #about-page {
        height: 290vh;
    }

    #lassonde {
        width: 13em;
        height: 10em;
        object-fit: cover;
    }

    .main-button {
        width: 10em;
    }

    #button-container {
        flex-direction: row;
    }

    .horizontal-list li {
        float: left;
    }

    .horizontal-list li {
        display: block;
        padding: 14px;
        text-align: center;
        text-decoration: none;
        text-align: center;
    }

    .med-header {
        font-size: 26px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    #language-list {
        margin-top: 0px;
        padding-left: 0px;
        margin-left: none !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    #framework-list {
        margin-top: 0px;
        padding-left: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    #down-button {
        margin-top: 5em;
    }

    #down-button-dark {
        -webkit-animation: breathing 1s ease-out infinite normal;
        animation: breathing 1s ease-out infinite normal;
        background: none;
        border: none;
        cursor: pointer;
        color: white;
        margin-top: 5em;
    }

    #down-button-dark-normal {
        background: none;
        border: none;
        cursor: pointer;
        color: white;
        margin-top: 5em;
    }

}

@mixin sp-layout {
    @media screen and (max-width: 750px) {
        @content;
    }
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
    overflow: hidden;
}

@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

.stars {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120%;
    transform: rotate(-45deg);
    z-index: 100;
}

.star {
    star-count: 20;
    --star-color: var(--primary-color);
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);
    z-index: 200;
    position: absolute;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: var(--star-color);
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;

    @include sp-layout {
        animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
    }

    @for $i from 1 through $star-count {
        &:nth-child(#{$i}) {
            --star-tail-length: #{random_range(500em, 750em) / 100};
            --top-offset: #{random_range(0vh, 10000vh) / 100};
            --fall-duration: #{random_range(6000, 12000s) / 1000};
            --fall-delay: #{random_range(0, 10000s) / 1000};
        }
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: calc(var(--star-width) / -2);
        width: var(--star-width);
        height: 100%;
        background: linear-gradient(45deg, transparent, currentColor, transparent);
        border-radius: inherit;
        animation: blink 2s linear infinite;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

@keyframes fall {
    to {
        transform: translate3d(-30em, 0, 0);
    }
}

@keyframes tail-fade {

    0%,
    50% {
        width: var(--star-tail-length);
        opacity: 1;
    }

    70%,
    80% {
        width: 0;
        opacity: 0.4;
    }

    100% {
        width: 0;
        opacity: 0;
    }
}

@keyframes blink {
    50% {
        opacity: 0.6;
    }
}